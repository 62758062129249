.colDetailed_root {
  /* height: calc(100vh - 65px); */
  min-height: calc(100vh - 65px);
  border-left: 2px solid #f2f2f2;
  background-color: #fcfcfd;
  overflow-y: auto;
}

.heading {
  font-size: 24px;
  font-weight: 600;
  padding: 2rem;
}

.heading_tab_bar {
  background-color: white !important;
}

.colDetailed_searchbar_container {
  display: flex;
  gap: 1rem;
  padding: 1rem 2rem 1rem;
  /* border-top: 1px solid #D7E0E9;
  border-bottom: 1px solid #D7E0E9; */
}

.dropdown_wrap {
  max-width: 80px !important;
  min-width: 80px !important;
}

.colDetailed_Btn_wrap {
  display: flex;
  gap: 0.6rem !important;
  padding-top: 1rem;
}

.filterBtn_wrap {
  padding-top: 0.7rem;
}

.filterBtn {
  color: white !important;
}

.exportBtn {
  background-color: white !important;
  padding: 0.4rem 1rem !important;
}

.exportBtn_wrap {
  padding-top: 0.7rem;
}

.reportBtn {
  background-color: white !important;
  padding: 0.4rem 1rem !important;
}

.reportBtn_wrap {
  padding-top: 0.7rem;
}

.filterColumn_icon .MuiIconButton-root {
  padding-top: 0 !important;
}

.filterColumn_icon {
  padding-top: 18px !important;
}

.column_filter_container {
  padding: 1rem !important;
}

.column_filter_text {
  font-size: 14px !important;
}

.col_table_container {
  border-radius: 0 !important;
  min-height: calc(100vh - 350px) !important;
  /* width: 100vw; */
}

.col_table_head_cell_filler {
  background-color: #e7f7f4 !important;
  max-width: 2px;
}

.col_table_head_cell_filler_end {
  background-color: #e7f7f4 !important;
  -width: 2px;
}

.col_table_head_cell {
  background-color: #e7f7f4 !important;
  /* width: 100%; */
}

.arrow_icon {
  cursor: pointer;
  padding: 16px 0 10px 16px !important;
  color: #697386 !important;
}

.patient_name {
  padding: 16px 16px 16px 2px !important;
}

.table_viewBtn {
  cursor: pointer;
  color: #14a6a6 !important;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.notes_dialogueContent {
  max-height: 500px;
  overflow-y: scroll;
}

.expand_cell {
  padding: 0 !important;
}

.expand_container {
  padding: 0.7rem 1.2rem;
  background-color: #fafbfd;
}

.expand_first_card {
  background-color: white;
  border: 1px solid #d7e0e9;
  padding: 0.8rem 1rem;
  display: flex;
  gap: 1rem;
}

.expand_card_item {
  color: #1a1f36;
  font-weight: 600;
  min-width: 125px;
}

.expand_second_card {
  background-color: white;
  border: 1px solid #d7e0e9;
  margin-top: 0.7rem;
  /* max-height: 200px; */
  overflow-y: hidden;
}

.expand_table_head_cell {
  padding: 12px 16px !important;
  background-color: #f3f2ef;
}

.grand_total_name {
  padding-left: 2.5rem !important;
}

.totals_name_column1 {
  padding-left: 2rem !important;
  color: black;
  font-weight: 700 !important;
  background-color: #fafbfd;
}

.totals_name {
  font-weight: 700 !important;
  color: black;
  background-color: #fafbfd;
}

@media (max-width: 1500px) {
  .dropdown_wrap {
    max-width: 120px !important;
  }
}