.login__page {
  font-family: "Montserrat", sans-serif;
  position: relative;
  background: #fcfcfd;
  margin: -8px;
}

.login__header {
  min-height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #f2f2f2;
  background-color: #f3f8f9;
}

.login__header__logo {
  width: 165px;
}

.login__header__bar {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100%;
  width: 100%; */
}

.login__header__logo {
  width: 165px;
}

.login__grid__subheading {
  font-size: 36px;
  font-weight: 600;
  letter-spacing: initial;
  line-height: 28px;
  color: #4f566b;
  /* padding-top: 2rem; */
  /* padding-bottom: 1rem; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.login__form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login__inputField {
  width: 250px !important;
}

.login_input {
  height: 60px;
}

.btnWrapper {
  padding-top: 15px;
}

.login__submitBtn {
  width: 100% !important;
  color: white !important;
  border: 0;
  cursor: pointer;
  height: 45px;
  padding: 10px 13px;
  font-size: 16px;
  min-width: 310px;
  background: #088b8c;
  min-height: 30px;
  transition: 0.3s ease all;
  font-weight: bold;
  border-radius: 4px;
  box-shadow: none !important;
  /* margin-top: 20px; */
}

.login__submitBtn:hover {
  background: #10a8a9;
}

.login__main {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login__section {
  display: flex;
  /* width: 80%;
  height: 80%; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 250px);
  /* margin-top: 5rem; */
}

.MuiFormHelperText-contained {
  margin-left: 0px !important;
}

.helper_text_wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-top: 1rem;
  /* padding-bottom: 1rem; */
}

.helper_text_large {
  color: #697386;
  font-size: 14px;
  padding-bottom: 0.5rem;
}

.helper_text_small {
  color: #a3acb9;
  font-size: 12px;
  /* padding-bottom: 4rem; */
}

.footer_container {
  display: flex;
  justify-content: space-evenly;
  gap: 2rem;
  border-top: 2px solid #f2f2f2;
  padding-top: 2rem;
  padding-bottom: 2rem;
  /* padding: 5rem 3rem 3rem 3rem; */
  background-color: #f3f8f9;
  /* position: fixed; */
  /* bottom: 50; */
  left: 0;
  right: 0;
}

.box_heading {
  color: #2f3034;
  font-size: 1.2rem;
  font-weight: 600;
  padding-bottom: 0.8rem;
}

.list_item {
  color: #2f3034;
  font-size: 16px;
  line-height: 25px;
  margin: 8px 0;
}

.footer_logo {
  width: 220px;
}

.list_addr {
  color: #2f3034;
  font-size: 18px;
  line-height: 25px;
  margin: 8px 0;
}

.number {
  color: #088b8c;
}

.footer_note {
  font-weight: 600;
  color: #4a4b4e;
  margin: 0 5rem;
  border-top: 2px solid #ccc;
}

.footer_content {
  padding-top: 10px;
  padding-bottom: 10px;
  /* margin-top: 1rem;
  margin-bottom: 3rem; */
}

a {
  text-decoration: none;
  color: #2f3034;
}
