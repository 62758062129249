.payor_table_container {
  border-radius: 0 !important;
  min-height: calc(100vh - 350px) !important;
}

.payor_table_head_cell {
  background-color: #e7f7f4 !important;
  height: 50px;
  /* font-size: 1rem !important; */
  font-weight: 600 !important;
}

.payor_root {
  min-height: calc(100vh - 65px);
  border-left: 2px solid #f2f2f2;
  background-color: #fcfcfd;
  overflow-y: auto;
}
.payor_name_cell{
  width: 250px;
}