.Dashboard_root {
  min-height: calc(100vh - 65px);
  border-left: 2px solid #f2f2f2;
  background-color: #fcfcfd;
  overflow-y: auto;
  overflow-x: hidden;
}

.dashboard_heading {
  font-size: 24px;
  font-weight: 600;
  padding: 2rem;
}

.dashboard_cards_container {
  display: flex;
  flex-wrap: wrap;
  padding: 2rem 2rem;
  gap: 2rem;
  /* width: 1600px; */
}

.graphHeading{
  /* border: 2px solid red; */
  padding:0;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 5px;
  justify-content: space-between;
  margin-left: 10px;
}

.card_heading {
  /* padding: 1rem 1rem 0; */
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  margin-left: 15px;
}

.subHeading{
  font-size: large;
  color: rgba(0, 0, 0, 0.729);
  padding-top: 10px;
  font-weight: bold;
  text-align: left;
}

.card_container {
  height: 390px;
  /* width: 380px; */
  width: 100%;
  border-radius: 8px;
  background-color: white;
}

.card_container_big {
  height: 470px;
  /* height: 65vh; */
  width: 585px;
  border-radius: 8px;
  background-color: white;
  /* border: 2px solid red; */
  width: 100%;
}

.card_arrow_icon {
  color: black;
}

/* .card_content .Target-root {
  height: 300px !important;
} */

/* .nivo_card_content {
  height: 310px;
  width: 500px;
} */

.nivo_card_content_big {
  height: 80%;
  width: 115%;
  
}

.nivo_card_content {
  height: 80%;
  width: 125%;
}

.dashboard_searchbar_container {
  display: flex;
  gap: 2rem;
  padding: 1.6rem 2rem 0.6rem;
}

.dashboard_dropdown_wrap {
  min-width: 150px !important;
}

.dashboard_filterBtn_wrap {
  padding-top: 0.7rem;
}

.dashboard_filterBtn {
  color: white !important;
  padding-right: 1rem;
}

.legend {
  display: flex;
  justify-content: center;
  padding: 8px auto 10px;
}

.legend_bullet_wrap {
  padding: 4px 10px 0px 0;
}

.legend_bullet {
  height: 10px;
  width: 10px;
  border-radius: 6px;
  background-color: #15afaf;
}

.legend_text {
  font-size: 14px;
}

.nivo_card_content > div > div > svg > g > g:nth-child(3) > text {
  transform: translate(-40px, 100px) !important;
}

.nivo_card_content_big > div > div > svg > g > g:nth-child(3) > text {
  transform: translate(-40px, 100px) !important;
}

@media(min-width:1700px){

}

/* .nivo_card_content > div > div > svg > g > g:nth-child(2) > g:first-child > text {
  color: #828282 !important;
  fill: #15afaf;
} */
