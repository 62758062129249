.prodSummary_root {
  /* height: calc(100vh - 65px); */
  min-height: calc(100vh - 65px);
  border-left: 2px solid #f2f2f2;
  background-color: #fcfcfd;
  overflow-y: auto;
}

.heading {
  font-size: 24px;
  font-weight: 600;
  padding: 2rem;
}

.heading_tab_bar {
  background-color: white !important;
  ;
}


.tab_container {
  padding: 0 !important;
}

.searchbar_container_prod {
  display: flex;
  gap: 1rem;
  /* justify-content: space-between; */
  /* border: 2px solid red; */
  padding: 1rem 2rem 1rem;
  width: 50vw;
  /* border:2px solid red */
  /* border-top: 1px solid #D7E0E9;
  border-bottom: 1px solid #D7E0E9; */
}

.dropdown_prod {
  max-width: 130px
}

.dropdown_wrap_container {
  padding-top: 1rem;
}

.filter_dropdown_wrap {
  max-width: 110px !important;
}

.production_date_dropdown {
  display: flex;
  align-items: center;
}

/* .dropdown_wrap_year {
  max-width: 120px !important;
} */

.MuiMenu-paper .MuiMenu-list {
  max-height: 210px !important;
}

.Btn_wrap {
  display: flex;
  gap: 0.7rem;
  padding-top: 1rem;
}

.filterBtn_wrap {
  padding-top: 0.7rem;
  cursor: pointer;
}

.filterBtn {
  color: white !important;
}

.exportBtn {
  background-color: white !important;
  padding: 0.4rem 1rem !important;
}

.exportBtn_wrap {
  padding-top: 0.7rem;
}

.table_sub_head_row {
  background-color: #fafbfd;
}

.headRow {
  /*  */
}

.table_sub_row_cell {
  /* border-right: 1px solid #afc6c6; */
  /* border-left: 1px solid #afc6c6; */
  /* border: 1px solid blue; */
  padding: 0 !important;
  height: 50px;
}

.table_sub_row {
  display: flex;
  /* border: 1px solid green; */
  height: 100%;
  width: 100% !important;
  /* gap: 1rem; */
}

.table_sub_head_cell {
  min-width: 85px;
  display: flex;
  text-align: center;
  align-items: center;
  padding-left: 5%;
  box-sizing: border-box;
  justify-content: left;
  border-right: 1px solid #afc6c6;
}

.billed {
  /* background-color: #c8ebeb; */
  background-color: #F6F6F6;
}

.paid {
  background-color: #DFEFF0
    /* background-color: #9fc5c5; */
}


.balance {
  background-color: #CEECF0;
  /* background-color: #F8F6F4; */
}

.table_total_sub_head_cell {
  /* min-width: 85px; */
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  box-sizing: border-box;
  border-left: 1px solid #afc6c6;
}



.table_body_sub_row {
  display: flex;
  height: 100%;
  width: 100%;
  /* gap: 1rem; */
}

.table_body_sub_row_cell {
  padding: 0 !important;
  height: 50px;
}

@media(min-width:2230px) {
  .dropdown_prod {
    width: 130px
  }
}

@media (min-width: 1760px) {
  .dropdown_prod {
    min-width: 140px
  }

  .searchbar_container_prod {
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem 1rem;
    width: 50vw;
    /* border: 2px solid green; */
  }

  .table_sub_head_cell {
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    padding-left: 5%;
    box-sizing: border-box;
    justify-content: left;
    border-right: 1px solid #afc6c6;
  }

  .table_body_sub_cell {
    display: flex;
    text-align: center;
    align-items: center;
    padding-left: 5%;
    box-sizing: border-box;
    justify-content: left;
    border-right: 1px solid #afc6c6;
  }

  .table_total_sub_head_cell {
    /* max-width: 500px; */
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    padding-left: 5%;
    box-sizing: border-box;
    justify-content: left;
    border-left: 1px solid #afc6c6;
  }
}

.tables {
  margin-bottom: 50px;
}

.table_body_sub_cell {
  /* min-width: 110px; */
  /* min-width: 85px; */
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  /* padding-left: 5%; */
  text-align: center;
  vertical-align: middle;
  box-sizing: border-box;
  /* justify-content: left; */
  justify-content: center !important;
  border-right: 1px solid #afc6c6;
  /* border-left: 1px solid #afc6c6; */
}

.month_name {
  border-right: 1px solid #afc6c6;
  border-left: 1px solid #afc6c6;
}

.prod_table_head_cell {
  border-right: 1px solid #afc6c6;
  border-left: 1px solid #afc6c6;
  border-top: 1px solid #afc6c6;
  border-bottom: 1px solid #afc6c6;
  background-color: #e7f7f4 !important;
  min-width: 60px;
}

.provider_name {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  cursor: pointer;
  /* min-width: 110px; */
  max-width: 60px;
  border-right: 1px solid #afc6c6;
  /* background-color: #F0F0F0 !important; */
  white-space: nowrap;
}

.provider_name_table2 {
  padding-left: 2rem !important;
  background-color: #fafbfd;
}

.provider_name_table2_text {
  color: black;
  font-weight: 600;
}

.month_name {
  padding-left: 2rem !important;
  box-sizing: border-box;

}

.totals_name_column1 {
  padding-left: 2rem !important;
  color: black;
  font-weight: 700 !important;
  background-color: #fafbfd;
  /* background-color: #F0F0F0 !important; */

}

.totals_name {
  font-weight: 700 !important;
  color: black;
  background-color: #fafbfd;
  border-right: 1px solid #afc6c6;
  border-left: 1px solid #afc6c6;
}

.graph_container {
  padding: 2rem;
}

.graph_card {
  /* height: 390px; */
  /* width: 1140px; */
  border-radius: 8px;
  background-color: white;
  padding: 2rem;
}

.graph_heading {
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 55px;
}

.graph_content {
  height: 400px;
}