.notes_heading {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
}

.notes_heading_text {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
}

.notes_closeBtn {
  background-color: white !important;
  padding: 2px 15px !important;
  border: 2px solid !important;
}

.notes_content {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  border-top: 1px solid #c4c4c4;
}

.notes_text {
  padding-right: 4rem;
  font-size: 16px;
  color: #36454c;
}

.notes_empty {
  min-width: 300px;
}

.notes_bullet {
  color: #1a1f36 !important;
  padding-left: 4px;
  padding-top: 0.6rem;
  font-weight: 500;
  display: flex;
}

.notes_bullet_icon_wrap {
  padding-top: 5px;
  padding-right: 6px;
}

.notes_bullet_icon {
  background-color: #1a1f36;
  height: 6px;
  width: 6px;
  border-radius: 5px;
}

/* .notes_bullet_text {
  display: flex;
  flex-wrap: nowrap;
} */

.notes_hideBtn {
  cursor: pointer;
  font-size: 15px;
  color: #14a6a6 !important;
  text-decoration: underline;
  text-underline-offset: 3px;
}
