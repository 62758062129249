.home__page {
  /* height: 100vh; */
  font-family: "Montserrat", sans-serif;
  position: relative;
  /* background: #fcfcfd; */
  background-color: #ffffff;
  margin: -8px;
  overflow-y: auto;
}

.home__header {
  min-height: 65px;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #f2f2f2;
  background-color: #ffffff;
}

.home__header__bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 95%;
}

.home_logoutBtn_wrap {
  color: #4f4f4f;
  font-size: 1.2rem;
  font-weight: 500;
}

.home_logoutBtn {
  color: white !important;
}

.home__header__logo {
  width: 165px;
}

.home__main {
  overflow-x: hidden;
  /* height: calc(100vh - 65px); */
}

.home_container {
  display: flex;
  /* height: 100%; */
  overflow: hidden;
}

.sidebar {
  /* height: 100%; */
  height: calc(100vh - 65px);
  min-width: 250px;
  background-color: #ffffff;
  /* border-right: 2px solid #f2f2f2; */
  border-radius: 0 !important;
}

.sidebar_list {
  height: auto;
  padding: 0;
  width: 100%;
}

.sidebar_list .sidebar_row {
  width: 100%;
  height: 48px;
  list-style-type: none;
  padding-left: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.sidebar_list .sidebar_row:hover {
  cursor: pointer;
}

.sidebar_list .sidebar_row_active {
  width: 100%;
  height: 48px;
  list-style-type: none;
  padding-left: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.bullet_wrap {
  padding-top: 0.3rem;
}

.bullet {
  height: 8px;
  width: 8px;
  background-color: #828282;
  border-radius: 5px;
}

.sidebar_item {
  color: #828282;
  font-size: 18px;
  display: flex;
}

.sidebar_row_active .bullet {
  height: 8px;
  width: 8px;
  background-color: black !important;
  border-radius: 5px;
}

.sidebar_row_active .sidebar_item {
  color: black !important;
  font-size: 18px;
  font-weight: 600;
  display: flex;
}

.sidebar_title {
  padding-left: 0.5rem;
}

.page_container {
  width: 100%;
  /* height: 100vh; */
  overflow-x: auto;
}

.empty {
  min-height: calc(100vh - 65px);
  border-left: 2px solid #f2f2f2;
  background-color: #fcfcfd;
}