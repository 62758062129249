.colSummary_root {
  /* height: calc(100vh - 65px); */
  min-height: calc(100vh - 65px);
  border-left: 2px solid #f2f2f2;
  background-color: #fcfcfd;
  overflow-y: auto;
}

.heading {
  font-size: 24px;
  font-weight: 600;
  padding: 2rem;
}

.heading_tab_bar {
  background-color: white !important;
}

.collection_sub_cell{
  border-right: 1px solid #afc6c6;
  border-left: 1px solid #afc6c6;
}
.tab_container {
  padding: 0 !important;
}

.searchbar_container {
  display: flex;
  gap: 3.5rem;
  /* border: 2px solid red;   */
  width: 65%;
  /* justify-content: space-between; */
  margin-left: 15px;
  /* padding: 1rem 2rem 1rem; */
  /* border-top: 1px solid #D7E0E9;
  border-bottom: 1px solid #D7E0E9; */
}

.filterBtn_wrap {
  padding-top: 0.7rem;
  cursor: pointer;
}


.dropdown_wrap_container {
  padding-top: 1rem;

}

.dropdown{
  width:150px ;
}

.filter_dropdown_wrap {
  max-width: 110px !important;
}

.production_date_dropdown {
  display: flex;
  align-items: center;
}

.production_datePicker {
  width: 7.5rem;
}

.dropdown_wrap_year {
  max-width: 120px !important;
}

.MuiMenu-paper .MuiMenu-list {
  max-height: 210px !important;
}

.Btn_wrap {
  display: flex;
  gap: 0.7rem;
  padding-top: 1rem;
}


.filterBtn {
  color: white !important;
}
.exportBtn {
  background-color: white !important;
  padding: 0.4rem 1rem !important;
}

.exportBtn_wrap {
  padding-top: 0.7rem;
}

.col_provider_name {
  /* padding-left: 2rem !important; */
  min-width: 100px;
}

.provider_name_table2 {
  padding-left: 2rem !important;
  background-color: #fafbfd;
}

.provider_name_table2_text {
  color: black;
  font-weight: 600;
}

.month_name {
  padding-left: 2rem !important;
}

.totals_name_column1 {
  padding-left: 2rem !important;
  color: black;
  font-weight: 700 !important;
  background-color: #fafbfd;
}

.totals_name {
  font-weight: 700 !important;
  color: black;
  background-color: #fafbfd;
}
